import React from 'react';

export default class Footer extends React.Component {
    render() {
        return <footer className="footer">
            <div className="container">
                <div className="footer__copyright">Единый ресурсный центр © 2020</div>
            </div>
        </footer>;
    }
}
