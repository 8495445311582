import React from 'react';
import Slider from "react-slick";
import { SliderSettings } from '../../shared/settings/slick-slider';

export default class RegistrationJuridicalPerson extends React.Component {

    constructor(props) {
        super(props);
        this.sliderSettings = new SliderSettings();
        this.secondSliderSettings = new SliderSettings();
        this.secondSliderSettings.slidesToScroll = 2;
        this.secondSliderSettings.slidesToShow = 2;
    }

    render() {
        return <section id="registrationJuridicalPerson" className="section registration-juridical-person">
            <div className="container container--large">
                <div className="section__header">
                    <h2 className="section__title wow animate__animated animate__fadeInRight">Регистрация юридических лиц</h2>
                    <h4 className="section__subtitle wow animate__animated animate__fadeIn animate__delay-1s">В Севастополе, КРЫМУ И  ЛЮБОМ РЕГИОНЕ РФ (возможно без Вашего участия, удаленно)</h4>
                    <div className="section__stock wow animate__animated animate__fadeIn animate__delay-1s">АКЦИЯ - при заключении договора на бухгалтерское сопровождение – регистрация юр. Лица или ИП в подарок</div>
                </div>
                <div className="services registration-juridical-person__item">
                    <Slider { ...this.sliderSettings } className="services__slider">

                        <div className="services__card-container">
                            <div className="service-card">
                                <div className="service-card__header">
                                    <h3 className="service-card__title">Регистрация ООО</h3>
                                    <div className="service-card__subtitle">Подготовка документов</div>
                                </div>
                                <div className="service-card__price"><span>3 800</span> руб</div>
                                <div className="service-card__info">
                                    <ol>
                                        <li>Предварительная консультация юриста</li>
                                        <li>Подбор кодов справочника ОКВЭД</li>
                                        <li>Подготовка полного комплекта документов для самостоятельной регистрации юр.лица</li>
                                    </ol>
                                </div>
                                <div className="service-card__additional">
                                    <span>Дополнительно к тарифу:</span>
                                    <p>(В стоимость не включено)</p>
                                    <ol>
                                        <li>Госпошлина - 4 000 руб</li>
                                        <li>Печать - 900 руб</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                        

                        <div className="services__card-container">
                            <div className="service-card">
                                <div className="service-card__header">
                                    <h3 className="service-card__title">Регистрация ООО</h3>
                                    <div className="service-card__subtitle">"Под ключ"</div>
                                </div>
                                <div className="service-card__price"><span>5 900</span> руб</div>
                                <div className="service-card__info">
                                    <ol>
                                        <li>Предварительная консультация юриста</li>
                                        <li>Подбор кодов справочника ОКВЭД</li>
                                        <li>Подготовка комплекта документов</li>
                                        <li>Подача документов в ИФНС</li>
                                        <li>Заявление о выборе системы налогообложения</li>
                                        <li>Получение извещений из ПФР, ФСС и статистики</li>
                                    </ol>
                                </div>
                                <div className="service-card__additional">
                                    <span>Дополнительно к тарифу:</span>
                                    <p>(В стоимость не включено)</p>
                                    <ol>
                                        <li>Госпошлина - 4 000 руб</li>
                                        <li>Печать - 900 руб</li>
                                        <li>Нотариальные расходы – от 3 500 руб</li>
                                    </ol>
                                </div>
                            </div>
                        </div>

                        <div className="services__card-container">
                            <div className="service-card services__card">
                                <div className="service-card__header">
                                    <h3 className="service-card__title">Регистрация ООО</h3>
                                    <div className="service-card__subtitle">«Под ключ» без вашего участия</div>
                                </div>
                                <div className="service-card__price"><span>6 900</span> руб</div>
                                <div className="service-card__info">Регистрация юр.лица удаленно «ПОД КЛЮЧ» без вашего участия и без оплаты пошлин и услуг нотариуса</div>
                            </div>
                        </div>

                    </Slider>             
                </div>
                <div id="juridicalAddresses" className="services registration-juridical-person__item">

                    <Slider { ...this.secondSliderSettings }  className="services__slider">

                        <div className="services__card-container services__card-container--small">
                            <div className="service-card">
                                <div className="service-card__header service-card__header--large">
                                    <h3 className="service-card__title">Юридические адреса</h3>
                                    <div className="service-card__subtitle">для регистрации предприятий</div>
                                </div>
                                <div className="service-card__price">от <span>19 000</span> руб в год</div>
                                <div className="service-card__info">Проверенная база данных, с подтверждением и почтовым обслуживанием</div>
                            </div>
                        </div>

                        <div className="services__card-container services__card-container--small">
                            <div className="service-card">
                                <div className="service-card__header service-card__header--large">
                                    <h3 className="service-card__title">Внесение изменений</h3>
                                    <div className="service-card__subtitle">В уставные документы</div>
                                </div>
                                <div className="service-card__price">от <span>4 000</span> руб</div>
                                <div className="service-card__info">Любой сложности</div>
                            </div>
                        </div>

                    </Slider>
                                    
                </div>
            </div>
        </section>;
    }
}
