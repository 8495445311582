import React from 'react';

export default class Header extends React.Component {
    render() {
        return <div id="header"className="header">
            <div className="container">
                <div className="header__container">
                    <div className="header__item header__title ">
                        <a href="/"><h1 className="wow animate__animated animate__backInDown">Единый ресурсный центр</h1></a>
                        <h4 className="wow animate__animated animate__fadeIn animate__delay-1s">Все услуги в одном офисе </h4>
                        <h5 className="wow animate__animated animate__fadeIn animate__delay-1s">юрист, бухгалтер, кадастровый инженер</h5>
                    </div>
                    <div className="header__item header__tel">
                        <a className="wow animate__animated animate__fadeIn animate__delay-1s" href="tel:+79787490203">+7 978 749-02-03</a>
                    </div>
                </div>
            </div>
        </div>;
    }
}
