import React from 'react';

export default class Accounting extends React.Component {
    render() {
        return <section id="accounting" className="section accounting">
            <div className="container">
                <div className="section__header">
                    <h2 className="section__title wow animate__animated animate__fadeInRight">Бухгалтерский учет</h2>
                    <h4 className="section__subtitle wow animate__animated animate__fadeIn animate__delay-1s">Для юридических лиц и предпринимателей</h4>
                </div>
                <p>Мы возьмем на себя все обязанности, связанные с бухгалтерским и налоговым учетом вашего 
                    предприятия – от учета первичных документов до сдачи бухгалтерской и налоговой отчетности</p>
                <p>Выбирая нашу компанию, Вы гарантированно получаете комплексное и профессиональное бухгалтерское 
                    обслуживание Вашего бизнеса. Эта услуга предназначена для тех, кто хочет полностью или частично 
                    передать ведение бухгалтерии профессиональной компании и получать качественные бухгалтерские услуги.</p>
        
                <p><span>Вы можете сосредоточиться на своём бизнесе, пока мы:</span></p>
                <ul>
                    <li>Ведем бухгалтерский  налоговый и кадровый учет</li>
                    <li>Готовим и сдаем отчетность</li>
                    <li>Следим за оплатой ваших налогов</li>
                    <li>Следим за изменениями в законодательстве</li>
                    <li>Оптимизируем налоги</li>
                    <li>Находим решения в сложных ситуациях</li>
                </ul>
        
                <p><span>Преимущества удаленной бухгалтерии</span></p>
                <ol>
                    <li>Вы полностью передаете ведение своей бухгалтерии, освобождая себя от необходимости самостоятельно 
                        заниматься финансовым учетом. Вы можете больше не думать о первичных документах, сдаче бухгалтерской 
                        и налоговой отчетностей.</li>
                    <li>Вы сможете снизить размер уплачиваемых вами налогов, проконсультировавшись со специалистами по 
                        вопросам уменьшения налоговой базы без нарушения законодательства. Кроме того, вы избавите себя 
                        от риска неправильно рассчитать сумму, необходимую для уплаты в бюджет. Вашей фирме будет оказана 
                        помощь в получении дополнительных вычетов из совокупного дохода. Специалисты по налогам проконсультируют 
                        вас по возможным налоговым последствиям отдельных сделок. Также можно проверить ваши договоры на предмет 
                        соответствия их последним дополнениям в сфере законодательства.</li>
                    <li>Вам не придется отслеживать верность заполнения бланков и документов, так как этим будут заниматься 
                        профессионалы.  Вы всегда сможете легко разобраться в своих бумагах, они не будут содержать ошибок 
                        и неточностей.</li>
                    <li>После передачи своей финансовой отчетности на попечение организации, предоставляющей услуги бухгалтерского 
                        сопровождения, исчезает необходимость ежемесячно принимать, обрабатывать и регистрировать все первичные 
                        документы, а также заниматься оформлением платежных поручений и расчетом налоговых выплат.</li>
                </ol>
        
                <p>Наши специалисты произведут все необходимые расчеты по зарплате ваших сотрудников, в том числе расчет отпуска, 
                    больничного листа, НДФЛ, страховых взносов. Также мы подготовим всю необходимую отчетность по заработной плате 
                    для контролирующих органов</p>
        
                <p><span>Стоимость услуг будет рассчитана в индивидуальном порядке, но зависеть она будет от следующих факторов:</span></p>
                <ul>
                    <li>Налоговый режим ООО или ИП</li>
                    <li>Наличие сотрудников в штате компании или ИП.</li>
                    <li>Количество осуществляемых операций.</li>
                    <li>Сферы деятельности ООО или ИП.</li>
                </ul>
                
                <p><span>АКЦИЯ ДЛЯ НОВЫХ КЛИЕНТОВ – СТОИМОСТЬ ОБСЛУЖИВАНИЯ В ТЕЧЕНИЕ 6 МЕСЯ ЦЕВ ДЕШЕВЛЕ НА 15%</span></p>
        
                <br></br>
        
                <p><span>Расчет стоимости бухгалтерского обслуживания</span></p>
                <div className="table">
                    <table>
                        <thead>
                            <tr>
                                <th>Количество операций в месяц</th>
                                <th>Предприятия и ИП На ОСНО</th>
                                <th>Предприятия на УСН 4% и 10% ЕНВД</th>
                                <th>ИП  на УСН 4% и 10%</th>
                                <th>ИП на патенте</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th>0 (нулевая)</th>
                                <th>От 5000 руб (квартал)</th>
                                <th>От 4500 руб (квартал)</th>
                                <th>4500 (квартал) без наемных работников</th>
                                <th>2500 (квартал) без наемных работников</th>
                            </tr>
                            <tr>
                                <th>До 30 (малое)</th>
                                <th>5500 руб в месяц без наемных работников</th>
                                <th>4500 руб в месяц без наемных работников</th>
                                <th>3500 руб в месяц без наемных работников</th>
                                <th>С наемными работниками от 3500 руб в мес</th>
                            </tr>
                            <tr>
                                <th>От 31 до 100(среднее)</th>
                                <th>7000-10000 руб в месяц без наемных работников</th>
                                <th>5000-8000руб в месяц без наемных работников</th>
                                <th>5000-8000 руб в месяц без наемных работников</th>
                                <th></th>
                            </tr>
                            <tr>
                                <th>От 100 до 200 крупное</th>
                                <th>12000-20000 руб в месяц без наемных работников</th>
                                <th>8000-15000руб в месяц без наемных работников</th>
                                <th>8000-15000 руб в месяц без наемных работников</th>
                                <th></th>
                            </tr>
                            <tr>
                                <th>От 200</th>
                                <th>По договоренности</th>
                                <th>По договоренности</th>
                                <th>По договоренности</th>
                                <th></th>
                            </tr>
                        </tbody>
                    </table>
                </div>
        
                <p><span>Стоимость расчета З/П и налогов</span></p>
                <div className="table">
                    <table>
                        <tbody>
                            <tr>
                                <th>1-5 человек</th>
                                <th>1000 руб за каждого человека</th>
                            </tr>
                            <tr>
                                <th>От 5 человек</th>
                                <th>5000 руб+500 руб. за одного человека</th>
                            </tr>
                        </tbody>
                    </table>
                </div>
        
            </div>
        </section>;
    }
}