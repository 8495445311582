import React from 'react';

export default class CadastralServices extends React.Component {
    render() {
        return <section id="cadastralServices" className="section cadastral-services">
            <div className="container">
                <div className="section__header">
                    <h2 className="section__title wow animate__animated animate__fadeInRight">Кадастровые услуги</h2>
                    <h4 className="section__subtitle wow animate__animated animate__fadeIn animate__delay-1s">Квалифицированные кадастровые инженеры только у нас</h4>
                </div>
                <div className="cadastral-services__container">
                    <div className="cadastral-services__image"></div>
                    <div className="cadastral-services__content">
                        <ul>
                            <li>Межевание земли 8 000 руб</li>
                            <li>Топосьемка участка 9 000 руб</li>
                            <li>Технические планы на строения 8 000 руб</li>
                            <li>Ввод дома в эксплуатацию 4 000 руб</li>
                            <li>Объединение/раздел участков 6 000 рубк</li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>;
    }
}
