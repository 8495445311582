import React from 'react';
import Slider from "react-slick";
import { SliderSettings } from '../../shared/settings/slick-slider';

export default class RegistrationIndividualEntrepreneur extends React.Component {

    constructor(props) {
        super(props);
        this.sliderSettings = new SliderSettings();
        this.sliderSettings.slidesToShow = 2;
        this.sliderSettings.slidesToScroll = 2;
    }

    render() {
        return <section id="registrationIndividualEntrepreneur" className="section registration-individual-entrepreneur">
            <div className="container container--large">
                <div className="section__header">
                    <h2 className="section__title wow animate__animated animate__fadeInRight">Регистрация ИП</h2>
                    <h4 className="section__subtitle wow animate__animated animate__fadeIn animate__delay-1s">Сопровождение регистрации индивидуальных предпринимателей в органах налоговой службы</h4>
                </div>
                <div className="services registration-individual-entrepreneur__content">
                    <Slider { ...this.sliderSettings } className="services__slider">

                        <div className="services__card-container">
                            <div className="service-card">
                                <div className="service-card__header">
                                    <h3 className="service-card__title">Регистрация ИП</h3>
                                    <div className="service-card__subtitle">Подготовка документов</div>
                                </div>
                                <div className="service-card__price"><span>1 000</span> руб</div>
                                <div className="service-card__info">
                                    <ol>
                                        <li>Предварительная консультация юриста</li>
                                        <li>Подбор кодов справочника ОКВЭД</li>
                                        <li>Подготовка полного комплекта документов для самостоятельной подачи и регистрации ИП</li>
                                    </ol>
                                </div>
                                <div className="service-card__additional">
                                    <span>Дополнительно к тарифу:</span>
                                    <p>(В стоимость не включено)</p>
                                    <ol>
                                        <li>Госпошлина - 800 руб</li>
                                        <li>Заявление на УСН, ЕНВД - 500 руб</li>
                                        <li>Переход на Патент - 1 000 руб</li>
                                        <li>Печать - 900 руб</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
            
                        <div className="services__card-container">
                            <div className="service-card">
                                <div className="service-card__header">
                                    <h3 className="service-card__title">Регистрация ИП</h3>
                                    <div className="service-card__subtitle">"Под ключ" дистанционно</div>
                                </div>
                                <div className="service-card__price"><span>5 900</span> руб</div>
                                <div className="service-card__info">
                                    <ol>
                                        <li>Предварительная консультация юриста</li>
                                        <li>Подбор кодов справочника ОКВЭД</li>
                                        <li>Подготовка полного комплекта документов</li>
                                        <li>Подача и получение документов дистанционно без вашего участия</li>
                                    </ol>
                                </div>
                                <div className="service-card__additional">
                                    <span>Дополнительно к тарифу:</span>
                                    <p>(В стоимость не включено)</p>
                                    <ol> 
                                        <li>Заявление на УСН, ЕНВД - 500 руб</li>
                                        <li>Переход на Патент - 1 000 руб</li>
                                        <li>Печать - 900 руб</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
            
                    </Slider>
                </div>
            </div>
        </section>;
    }
}
