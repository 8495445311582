import React from 'react';

export default class PropertyRegistration extends React.Component {
    render() {
        return <section id="propertyRegistration" className="section property-registration">
            <div className="container">
                <div className="section__header">
                    <h2 className="section__title wow animate__animated animate__fadeInRight">Оформление недвижимости</h2>
                </div>
                <div className="property-registration__container">
                    <div className="property-registration__content">
                        <ul>
                            <li>Приватизация земли  15 000 руб</li>
                            <li>Разрешение на строительство 9 000 руб</li>
                            <li>Ввод объектов в эксплуатацию 8 000 руб</li>
                            <li>Внесение сведений  в НРС от 5 000 руб. за сотрудника</li>
                            <li>Получение ТУ на подвод коммуникаций 4 000 руб</li>
                            <li>Регистрация сделок  купли/продажи 3 000 руб</li>
                            <li>Помощь при отводе земельных участков, оформление в аренду, выкуп</li>
                            <li>Оценка недвижимости 2 000 руб</li>
                            <li>Подача документов в МФЦ 4000 руб</li>
                        </ul>
                    </div>
                    <div className="property-registration__image"></div>
                </div>
            </div>
        </section>;
    }
}
