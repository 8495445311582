import React from 'react';
import ArrowUpwardRoundedIcon from '@material-ui/icons/ArrowUpwardRounded';
import { Link } from 'react-scroll';

export const SCROLL_TOP_MIN = 300;

export default class ScrollTopButton extends React.Component {

    constructor() {
        super();
        this.state = {
            scrollTopButtonVisible: window.scrollY > SCROLL_TOP_MIN
        };
        this.scrollFromTop = this.scrollFromTop.bind(this);
    }

    scrollFromTop() {
        this.setState({ ...this.state,
            scrollTopButtonVisible: window.scrollY > SCROLL_TOP_MIN
        });
    }

    render() {
        window.addEventListener('scroll', this.scrollFromTop);
        return <div>
            {
                this.state.scrollTopButtonVisible &&
                <Link className="scroll-top-button" to={'header'} spy={true} smooth={true} duration={1000}>
                    <ArrowUpwardRoundedIcon className="material-icons scroll-top-button__icon" />
                </Link>
            }
        </div>;
    }
}
