import React from 'react';
import Home from './home/home';
import TopBar from './shared/components/topbar';
import Header from './shared/components/header';
import Footer from './shared/components/footer';
import { ReactComponent as Logo } from '../assets/icons/logo.svg';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';

const theme = createMuiTheme({
  palette: {
    primary: {
      // Purple and green play nicely together.
      main: '#2980B9',
    },
    secondary: {
      // This is green.A700 as hex.
      main: '#11cb5f',
    }
  },
});

function App() {
  return <ThemeProvider theme={theme}>
    <Header />
    <div className="logo container">
      <Logo className="logo__icon wow animate__animated animate__backInLeft"/>
    </div>
    <TopBar />
    <Home />
    <Footer />
  </ThemeProvider>;
}

export default App;
