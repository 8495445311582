import React from 'react';
import { Map as LeafletMap, TileLayer, Marker, Popup } from 'react-leaflet';

export default class Contacts extends React.Component {

    mapSetting = {
        coordinates: [44.607539, 33.518232],
        zoom: 18
    }

    render() {
        return <section id="contacts" className="section contacts">
            <div className="container contacts__container">
                <LeafletMap className="contacts__map" center={this.mapSetting.coordinates} zoom={this.mapSetting.zoom}>
                    <Marker position={this.mapSetting.coordinates}>
                        <Popup>Единый ресурсный центр ул. Кулакова, 15</Popup>
                    </Marker>
                    <TileLayer attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"/>
                </LeafletMap>
                <div className="contacts__content wow animate__animated animate__fadeIn">
                    <h2 className="section__title contacts__title wow animate__animated animate__fadeInRight">Контакты</h2>
                    <p>299011 Крым Севастополь</p>
                    <p>ул. Кулакова, 15</p>
                    <p>Тел.: +7 (978) 749-02-03</p>
                    <p>Время работы: c 09-00 до 18-00</p>
                    <p>(Воскресенье - выходной)</p>
                    <p>Email: tmaria@list.ru</p>
                </div>
            </div>
        </section>;
    }    
}
