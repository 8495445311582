import React from 'react';
import AboutUs from './sections/section-1';
import JuridicalServices from './sections/section-2';
import History from './sections/section-3';
import RegistrationJuridicalPerson from './sections/section-4';
import RegistrationIndividualEntrepreneur from './sections/section-5';
import LiquidationJuridicalPerson from './sections/section-6';
import Accounting from './sections/section-7';
import CrimeaSro from './sections/section-8';
import PropertyRegistration from './sections/section-9';
import CadastralServices from './sections/section-10';
import ConstructionInterim from './sections/section-11';
import Contacts from './sections/section-12';
import CallButton from '../shared/components/call-button';
import ScrollTopButton from '../shared/components/scroll-top-button';

export default class Home extends React.Component {
  render() {
    return <div className="Home">
      <AboutUs />
      <JuridicalServices />
      <History />
      <RegistrationJuridicalPerson />
      <RegistrationIndividualEntrepreneur />
      <LiquidationJuridicalPerson />
      <Accounting />
      <CrimeaSro />
      <PropertyRegistration />
      <CadastralServices />
      <ConstructionInterim />
      <Contacts />
      <CallButton />
      <ScrollTopButton />
    </div>;
  }
}
