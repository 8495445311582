import React from 'react';

export default class ConstructionInterim extends React.Component {
    render() {
        return <section className="section construction-interim">
            <div className="container">
                <div className="section__header">
                    <h2 className="section__title wow animate__animated animate__fadeInRight">Комплексное сопровождение строительства вашего дома</h2>
                    <h4 className="section__subtitle wow animate__animated animate__fadeIn animate__delay-1s">От подбора земельного  участка до ввода дома в эксплуатацию и получения права собственности на него</h4>
                </div>
                <div className="construction-interim__container">
                    <div className="construction-interim__content">
                        <ul>
                            <li>Подбор и проверка чистоты сделки на покупку земельного участка</li>
                            <li>Сопровождение или оформление сделки купли/продажи</li>
                            <li>Получение ТУ на подвод коммуникаций</li>
                            <li>Проектирование и строительство</li>
                            <li>Ввод в эксплуатацию с получением выписки о праве собственности</li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>;
    }
}
