import React from 'react';
import MenuRounded from '@material-ui/icons/MenuRounded';
import { AppBar, Toolbar, IconButton, Button, Drawer } from '@material-ui/core';
import { Link } from 'react-scroll';

export const TABLET_WIDTH = 1080;

export default class TopBar extends React.Component {

    

    constructor() {
        super();
        this.setMenu();
        this.state = {
            menuIsVisible: false,
            drawerVisible: document.body.clientWidth < TABLET_WIDTH
        };
        this.toggleMenu = this.toggleMenu.bind(this);
        this.resize = this.resize.bind(this);
    }

    toggleMenu(isVisible) {
        return () => {
            this.setState({ ...this.state,
                menuIsVisible: isVisible
            });
        };
    }

    resize() {
        this.setMenu();
        this.setState({ ...this.state,
            drawerVisible: document.body.clientWidth < TABLET_WIDTH
        });
    }

    setMenu() {
        if (document.body.clientWidth < TABLET_WIDTH) {
            this.menuItems = [
                { link: 'registrationJuridicalPerson', label: 'Регистрация предприятий'},
                { link: 'registrationIndividualEntrepreneur', label: 'Регистрация ИП'},
                { link: 'juridicalAddresses', label: 'Юридические адреса'},
                { link: 'accounting', label: 'Бухгалтерия для ОО, ИП'},
                { link: 'liquidationJuridicalPerson', label: 'Ликвидация предприятий'},
                { link: 'liquidationJuridicalPerson', label: 'Ликвидация ИП'},
                { link: 'juridicalAddresses', label: 'Внесение изменений в уставные документы'},
                { link: 'cadastralServices', label: 'Кадастровые услуги'},
                { link: 'propertyRegistration', label: 'Оформление недвижимости'},
                { link: 'propertyRegistration', label: 'Оценка недвижимости'},
                { link: 'sro', label: 'Вступление в СРО'},
                { link: 'juridicalServices', label: 'О компании'},
                { link: 'contacts', label: 'Контакты'}
            ]
        } else {
            this.menuItems = [
                { link: 'registrationJuridicalPerson', label: 'Регистрация предприятий'},
                { link: 'juridicalAddresses', label: 'Юридические адреса'},
                { link: 'liquidationJuridicalPerson', label: 'Ликвидация предприятий'},
                { link: 'juridicalServices', label: 'О компании'},
                { link: 'contacts', label: 'Контакты'}
            ]
        }
    }

    navMenu() {
        const menu = this.menuItems.map((menuItem, menuItemIndex) => {
            return <Button key={menuItemIndex} className="topbar__button">
                <Link key={menuItemIndex} to={menuItem.link} offset={-64} spy={true} smooth={true} duration={1000}>{menuItem.label}</Link>
            </Button>
        });
            
        return <div className="topbar__menu">
            { menu }
        </div>;
    }
    
    render() {
        window.addEventListener('resize', this.resize);

        return <AppBar position="sticky" className="topbar">
            <Toolbar>
                <div className="container topbar__container">
                    { 
                        this.state.drawerVisible 
                            ? <div>
                                <IconButton onClick={this.toggleMenu(true)} className="topbar__menu-button">
                                    <MenuRounded style={{ color: '#ffffff' }}/>
                                </IconButton>
                                <Drawer anchor={'left'} open={this.state.menuIsVisible} onClose={this.toggleMenu(false)}>
                                    <div className="topbar__menu-title">Единый Ресурсный Центр</div>
                                    { this.navMenu() }
                                </Drawer>
                            </div>
                            : this.navMenu()
                    }
                </div>
            </Toolbar>
        </AppBar>
    }
}
