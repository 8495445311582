import React from 'react';
import Slider from "react-slick";
import { SliderSettings } from '../../shared/settings/slick-slider';

export default class LiquidationJuridicalPerson extends React.Component {

    constructor(props) {
        super(props);
        this.sliderSettings = new SliderSettings();
        this.sliderSettings.responsive = [
            {
                breakpoint: 1079,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 2,
                }
            },
            {
                breakpoint: 880,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1,
                }
            }
        ];
    }

    render() {
        return <section id="liquidationJuridicalPerson" className="section liquidation-juridical-person">
            <div className="container container--large">
                <div className="section__header">
                    <h2 className="section__title wow animate__animated animate__fadeInRight">Ликвидация Юридических лиц</h2>
                    <h4 className="section__subtitle wow animate__animated animate__fadeIn animate__delay-1s">в Севастополе, Крыму и любом регионе РФ</h4>
                </div>
                <div className="services">
                    <Slider { ...this.sliderSettings } className="services__slider">
            
                        <div className="services__card-container services__card-container--large">
                            <div className="service-card">
                                <div className="service-card__header service-card__header--large">
                                    <h3 className="service-card__title">Ликвидация ооо (удаленно)</h3>
                                    <div className="service-card__subtitle">Без долгов, ликвидатор - директор предприятия</div>
                                </div>
                                <div className="service-card__price"><span>22 000</span> руб</div>
                                <div className="service-card__price-info">
                                    <p>Возможна оплата в 2 этапа:</p>
                                    <p>1 этап: начало работ - 50%,</p>
                                    <p>2 этап: после принятия ИФНС Положительного решения о Начале Процедуры Ликвидации</p>
                                </div>
                                <div className="service-card__info">
                                    <ol>
                                        <li>Предварительная консультация юриста</li>
                                        <li>Подготовка комплекта документов для ликвидации</li>
                                        <li>Публикация в Вестнике государственной регистрации</li>
                                        <li>Составление промежуточного и ликвидационного баланса</li>
                                        <li>Запрос сверок о состоянии расчетов с контролирующими органами</li>
                                    </ol>
                                </div>
                                <div className="service-card__additional">
                                    <span>Дополнительно к тарифу:</span>
                                    <p>(В случае необходимости)</p>
                                    <ol>
                                        <li>Восстановление учета и отчетности</li>
                                        <li>Сопровождение выездной налоговой проверки</li>
                                    </ol>
                                </div>
                            </div>
                        </div>

                        <div className="services__card-container services__card-container--large">
                            <div className="service-card">
                                <div className="service-card__header service-card__header--large">
                                    <h3 className="service-card__title">Ликвидация ооо</h3>
                                    <div className="service-card__subtitle">С долгами, под ключ, банкротство ликвидатор - директор</div>
                                </div>
                                <div className="service-card__price"><span>300 000</span> руб</div>
                                <div className="service-card__price-info">
                                    <p>Возможна оплата в 2 этапа:</p>
                                    <p>1 этап: начало работ - 50%,</p>
                                    <p>2 этап: после принятия ИФНС Положительного решения о Начале Процедуры Ликвидации</p>
                                </div>
                                <div className="service-card__info">
                                    <ol>
                                        <li>Предварительная консультация юриста</li>
                                        <li>Подготовка комплекта документов для ликвидации</li>
                                        <li>Оплата госпошлины и услуг нотариуса</li>
                                        <li>Публикация в Вестнике государственной регистрации</li>
                                        <li>Составление промежуточного и ликвидационного баланса</li>
                                        <li>Запрос сверок о состоянии расчетов с контролирующими органами</li>
                                        <li>Ведение дела о несостоятельности (банкротстве) в Арбитражном суде</li>
                                    </ol>
                                </div>
                                <div className="service-card__additional">
                                    <span>Дополнительно к тарифу:</span>
                                    <p>(В случае необходимости)</p>
                                    <ol>
                                        <li>Восстановление учета и отчетности</li>
                                        <li>Сопровождение выездной налоговой проверки</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
            
                        <div className="services__card-container services__card-container--large">
                            <div className="service-card">
                                <div className="service-card__header service-card__header--large">
                                    <h3 className="service-card__title">Закрытие (Ликвидация) ИП</h3>
                                    <div className="service-card__subtitle">в Севастополе, КРЫМУ и любом регионе РФ</div>
                                </div>
                                <div className="service-card__price"><span>3 500</span> руб</div>
                                <div className="service-card__info">за 5 рабочих дней (без Личного присутствия)</div>
                            </div>
                        </div>

                    </Slider>
                </div>
            </div>
        </section>;
    }
}
