import React from 'react';
import CallRoundedIcon from '@material-ui/icons/CallRounded';

export default class CallButton extends React.Component {
    render() {
        return <a className="call-button" href="tel:+79787490203">
            <CallRoundedIcon className="material-icons call-button__icon" />
        </a>;
    }
}
