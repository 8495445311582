import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded';
import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded';

function SampleNextArrow(props) {
    const { onClick } = props;
    return <IconButton aria-label="prev" onClick={onClick}><ArrowForwardIosRoundedIcon /></IconButton>;
}
function SamplePrevArrow(props) {
    const { onClick } = props;
    return <IconButton aria-label="prev" onClick={onClick}><ArrowBackIosRoundedIcon /></IconButton>;
}

export class SliderSettings {
    dots = true;
    arrow = true;
    infinite = false;
    speed = 500;
    slidesToShow = 3;
    slidesToScroll = 3;
    nextArrow = <SampleNextArrow />;
    prevArrow = <SamplePrevArrow />;
    responsive = [
        {
            breakpoint: 1079,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
            }
        },
        {
            breakpoint: 700,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            }
        }
    ]
}