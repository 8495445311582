import React from 'react';
import Slider from "react-slick";
import { SliderSettings } from '../../shared/settings/slick-slider';
import SrcBanner1 from '../../../assets/img/src-banner-1.jpg';

export default class AboutUs extends React.Component {

    constructor(props) {
        super(props);
        this.sliderSettings = new SliderSettings();
        this.sliderSettings.slidesToShow = 1;
        this.sliderSettings.slidesToScroll = 1;
        this.sliderSettings.responsive = null;
    }

    render() {
        return <section id="aboutUs" className="section about-us">
            <div className="container about-us__container">
                <Slider { ...this.sliderSettings } className="about-us__slider">

                    <div className="about-us__item">
                        <img src={SrcBanner1} alt="banner"/>
                    </div>

                </Slider>  
            </div>
        </section>;
    }
}
