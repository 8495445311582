import React from 'react';

export default class CrimeaSro extends React.Component {
    render() {
        return <section id="sro" className="section sro">
            <div className="container">
                <div className="section__header">
                    <h2 className="section__title wow animate__animated animate__fadeInRight">Вступление в региональное Крымское СРО</h2>
                </div>
                <div className="sro__container">
                    <div className="sro__image"></div>
                    <div className="sro__content">
                        <ul>
                            <li>Строительство</li>
                            <li>Проектирование</li>
                            <li>Изыскания</li>
                            <li>Внесение сведений  в НРС от 5000 руб. за сотрудника</li>
                            <li>Помощь в прохождении проверок</li>
                            <li>Повышение квалификации/обучение сотрудников</li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>;
    }
}
