import React from 'react';

export default class History extends React.Component {
    render() {
        return <section className="section history">
            <div className="container">
                <div className="history__content">
                <div className="history__item">
                    <span>15</span>
                    <p>лет на рынке юридических услуг в Крыму и Севастополе</p>
                </div>
                <div className="history__item">
                    <span>257</span>
                    <p>зарегистрированных предприятий и предпринимателей</p>
                </div>
                <div className="history__item">
                    <span>102</span>
                    <p>постоянных клиентов доверяют нам сопровождение своего бизнеса</p>
                </div>
                <div className="history__item">
                    <span>65</span>
                    <p>предприятий вступивших в СРО с нашей помощью</p>
                </div>
                <div className="history__item">
                    <span>547</span>
                    <p>клиентов которые построили и оформили свою недвижимость с нами</p>
                </div>
                </div>
            </div>
        </section>;
    }
}
