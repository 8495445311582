import React from 'react';
import { ReactComponent as Signature } from '../../../assets/icons/signature.svg';
import { ReactComponent as LegalDocument } from '../../../assets/icons/legal-document.svg';
import { ReactComponent as Box } from '../../../assets/icons/box.svg';
import { ReactComponent as OfficeBuilding } from '../../../assets/icons/office-building.svg';
import { ReactComponent as Work } from '../../../assets/icons/work.svg';
import { Link } from 'react-scroll';

export default class JuridicalServices extends React.Component {
    render() {
        return <section id="juridicalServices" className="section juridical-services">
            <div className="container container--large">
                <div className="section__header">
                    <h2 className="section__title wow animate__animated animate__fadeInRight">Юридические услуги в Крыму</h2>
                    <h4 className="section__subtitle wow animate__animated animate__fadeIn animate__delay-1s">Компания ориентирована на комплексное решение задач клиента. Консультируем и сопровождаем любые сделки.</h4>
                </div>
                <div className="juridical-services__content animate__animated animate__flipInX animate__delay-1s">
                    <div className="juridical-services-card juridical-services__card">
                        <div className="juridical-services-card__icon">
                            <Signature className="icon"/>
                        </div>
                        <h3 className="juridical-services-card__title">
                            <Link to="registrationJuridicalPerson" offset={-64} spy={true} smooth={true} duration={1000}>Регистрация ООО, ИП</Link>
                        </h3>
                        <div className="juridical-services-card__info">Сопровождение в регистрации ООО, ИП в органах налоговой службы.</div>
                    </div>
                    <div className="juridical-services-card juridical-services__card">
                        <div className="juridical-services-card__icon">
                            <LegalDocument className="icon"/>
                        </div>
                        <h3 className="juridical-services-card__title">
                            <Link to="liquidationJuridicalPerson" offset={-64} spy={true} smooth={true} duration={1000}>Ликвидация ООО, ИП</Link>
                        </h3>
                        <div className="juridical-services-card__info">Сопровождение в ликвидации общества с ограниченной ответственностью в органах налоговой службы.</div>
                    </div>
                    <div className="juridical-services-card juridical-services__card">
                        <div className="juridical-services-card__icon">
                            <Box className="icon"/>
                        </div>
                        <h3 className="juridical-services-card__title">
                            <Link to="accounting" offset={-64} spy={true} smooth={true} duration={1000}>Бухгалтерский учет для юр.лиц и ИП</Link>
                        </h3>
                        <div className="juridical-services-card__info"></div>
                    </div>
                    <div className="juridical-services-card juridical-services__card">
                        <div className="juridical-services-card__icon">
                            <OfficeBuilding className="icon"/>
                        </div>
                        <h3 className="juridical-services-card__title">
                            <Link to="propertyRegistration" offset={-64} spy={true} smooth={true} duration={1000}>Оформление недвижимости</Link>
                        </h3>
                        <div className="juridical-services-card__info"></div>
                    </div>
                    <div className="juridical-services-card juridical-services__card">
                        <div className="juridical-services-card__icon">
                            <Work className="icon"/>
                        </div>
                        <h3 className="juridical-services-card__title">
                            <Link to="cadastralServices" offset={-64} spy={true} smooth={true} duration={1000}>Кадастровые услуги</Link>
                        </h3>
                        <div className="juridical-services-card__info"></div>
                    </div>
                </div>
            </div>

            <div className="container juridical-services__info">
                <b>Миссия нашей работы</b> - профессиональная поддержка клиентов в сфере оформления любых юридических,  бухгалтерских и кадастровых документов  с предоставлением гарантии качества оказываемых услуг. В нашей организации работают только высококвалифицированные бухгалтера, юристы и кадастровые инженеры. Они всегда  готовы решить любую вашу  задачу  в кратчайшие сроки.
    
                <p className="annotation">Задать любые интересующие Вас вопросы и получить консультацию по всем услугам можно по телефону <a href="tel:+79787490203"><b>+7 978 749-02-03</b></a> или в офисе по адресу: <b>г. Севастополь, ул. Кулакова, 15</b></p>
            </div>
        </section>;
    }
}
